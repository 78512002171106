import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow-blue {
    text-shadow: 0 3px 3px #003057;
  }
`

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  background: #A6192E;
`

const PagePrivacySection = () => (
  <>
    <div className="relative" style={{ display: "grid", background: "#423E42" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 300,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Terms Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={1920}
        className="hidden md:block"
      />

      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 200,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Terms Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={800}
        className="block md:hidden"
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
          <StyledDiv className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
            <h2 className='text-4xl lg:text-5xl font-medium text-white text-center text-shadow-blue uppercase mt-6'>
              <span className='leading-tight'>Privacy Policy</span>
            </h2>
          </StyledDiv>
        </div>
      </div>
    </div>

    <div className="w-full bg-gray-100">
      <div className='relative w-11/12 max-w-screen-xl bg-gray-100 py-12 md:py-16 lg:w-10/12 lg:py-20 mx-auto'>
        <div className='w-full md:w-9/12 mx-auto'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Tiarna Real Estate Services Privacy Policy</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>
        </div>

        <div className='w-full md:w-9/12 mx-auto'>

          <p className='text-xl text-gray-900 mb-6'>Your privacy is important to us. It is Tiarna's policy to respect your privacy regarding any information we may collect from you across our website, www.tiarna.com, and other sites we own and operate.</p>

          <p className='text-xl text-gray-900 mb-6'>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>

          <p className='text-xl text-gray-900 mb-6'>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>

          <p className='text-xl text-gray-900 mb-6'>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>

          <p className='text-xl text-gray-900 mb-6'>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>

          <p className='text-xl text-gray-900 mb-6'>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>

          <p className='text-xl text-gray-900 mb-6'>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>

          <p className='text-xl text-gray-900 mb-6'>This policy is effective as of 13 March 2023.</p>
        </div>
      </div>
    </div>
  </>
)

export default PagePrivacySection