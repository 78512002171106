import * as React from "react"

import LayoutPage from "../components/layout/layout-page"
import PagePrivacySection from "../components/sections/page-privacy-section"
import Seo from "../components/seo"

const PrivacyPage = () => (
  <LayoutPage>
    <Seo title="Privacy Policy" />

    <PagePrivacySection />
  </LayoutPage>
)

export default PrivacyPage
